import { createStore } from 'vuex'

export default createStore({
  state: {
    tourPackagesList: [
      {
        id: 1,
        title_uz: "O'zbekiston Sharqdan G'arbga Poyezdda",
        title_ru: "Узбекистан с Востока на Запад на поезде",
        title_en: "Uzbekistan from East to West on a Train",
        description_uz: "Ipak yo'lini eng yaxshi tarzda ko'ring: O'zbekistonda unutilmas poyezd sayohati bilan! Biz Toshkentdan boshlab sharqqa qarab, mashhur kulollar va ipakchilarni uchratish uchun kamdan-kam bo'ladigan Farg'ona vodiysiga boramiz. Keyinroq janubiy eski karvon yo'li bo'ylab yuramiz, u yerda Buxoro va Samarqandning go'zal islom me'morchiligini ko'rasiz. Shuningdek, cheksiz Qizilqum bo‘ylab sayohat qilib, Xiva yaqinidagi Qadimgi Xorazmga tashrif buyurib, cho‘ldagi hayotni ko‘rasiz.",
        description_ru: "Посмотрите на Шелковый путь наилучшим образом: совершив незабываемое путешествие на поезде по Узбекистану! Начиная с Ташкента, мы направляемся на восток, в редко посещаемую Ферганскую долину, чтобы встретиться со знаменитыми гончарами и ткачами шелка. Позже мы следуем по старому караванному маршруту на юг, где вы увидите прекрасную исламскую архитектуру Бухары и Самарканда. Вы также получите представление о жизни в пустыне, проехав через бескрайние Кызылкумы и посетив Древний Хорезм недалеко от Хивы.",
        description_en: "See the Silk Road in the best possible way: with an unforgettable train journey in Uzbekistan! Starting in Tashkent we head east to rarely visited Ferghana valley to meet famous potters and silk weavers. Later we follow an old caravan route south where you see the fine Islamic architecture of Bukhara and Samarkand. You will also get a glimpse of life in the desert by driving through endless Kyzylkum and visiting Ancient Khorezm near Khiva.",
        price_uz: "1060$ dan boshlab",
        price_ru: "от 1060$",
        price_en: "from 1060$",
        duration_uz: "12 kun, 11 kecha",
        duration_ru: "12 дней, 11 ночей",
        duration_en: "12 days, 11 nights",
        delay: "0.1s",
        image: "/img/package-1.jpg"
      },
      {
        id: 2,
        title_uz: "Klassik 5-Qoya sarguzashtlari",
        title_ru: "Классическое приключение на 5 Стэнов",
        title_en: "Classic 5-Stan adventure",
        description_uz: "Buyuk Ipak yo‘lining sehrli muhitini his eting: atigi 20 kun ichida siz Markaziy Osiyodagi 5 xilma-xil yangi xalqlarning diqqatga sazovor joylariga ega bo‘lasiz. Markaziy Osiyoning 5 ta yirik shaharlari: Toshkent, Olmaota, Bishkek, Dushanbe va Ashxobodda gavjum zamonaviy tomonlarini ko'ring. Qirg‘iziston va Tojikistondagi mahalliy cho‘ponlar va yarim ko‘chmanchi chorvadorlar bilan tanishing. Jahannam darvozalari deb nomlanuvchi alangali gaz krateriga tashrif buyuring. O‘zbekiston va Turkmanistonning Samarqand, Buxoro, Xiva, Marv va Nissa shaharlarining go‘zalligiga qoyil qoling.",
        description_ru: "Почувствуйте волшебную атмосферу Великого Шелкового пути: всего за 20 дней вы познакомитесь с 5 разнообразными новыми странами Центральной Азии. Посмотрите на оживленную современную сторону Центральной Азии в ее 5 крупнейших городах: Ташкенте, Алматы, Бишкеке, Душанбе и Ашхабаде. Познакомьтесь с местными пастухами и полукочевыми пастухами в Кыргызстане и Таджикистане. Посетите пылающий газовый кратер, известный как Врата Ада. Полюбуйтесь красотой городов, входящих в список Всемирного наследия ЮНЕСКО, Самарканда, Бухары, Хивы, Мерва и Нисы в Узбекистане и Туркменистане.",
        description_en: "Feel the magic atmosphere of the Great Silk Road: in just 20 days, you will get the highlights of 5 diverse new nations in Central Asia. See the bustling modern side of Central Asia in its 5 biggest cities: Tashkent, Almaty, Bishkek, Dushanbe and Ashgabad. Meet local shepherds and semi-nomadic herdsmen in Kyrgyzstan and Tajikistan. Visit the flaming gas crater known as the Gates of Hell. Admire the beauty of Unesco World Heritage cities Samarkand, Bukhara, Khiva, Merv and Nissa in Uzbekistan and Turkmenistan.",
        price_uz: "2400$ dan boshlab",
        price_ru: "от 2400$",
        price_en: "from 2400$",
        duration_uz: "21 kun, 20 kecha",
        duration_ru: "21 дней, 20 ночей",
        duration_en: "21 days, 20 nights",
        delay: "0.3s",
        image: "/img/package-2.jpg"
      },
      {
        id: 3,
        title_uz: "Bayqo'ng'ir kosmodromi - raketa uchirish",
        title_ru: "Космодром Байконур – запуски ракет",
        title_en: "Baikonur Spaceport – Rocket launch",
        description_uz: "\"Boyqo'ng'ir\" kosmodromi: Qozog'istonning cheksiz cho'llarida joylashgan, kosmik tadqiqotlar tarixidagi eng qadimiy joylardan biri. Yuriy Gagarinning izlarini kuzatib boring va ruscha uslubdagi raketa uchishini yaqindan ko'ring. Bayqoʻngʻir Rossiyaning Qozogʻiston ichidagi kichik anklavi boʻlib, harbiy hududi cheklangan. Amalda, bu tashrif qimmat va barcha kerakli hujjatlar va ruxsatnomalarni olish uchun oldindan rejalashtirish kerakligini anglatadi. Yilning istalgan vaqtida tashrif buyurishingiz mumkin, lekin, albatta, ishga tushirishni ko'rish - bu tortdagi gilos. Biroq, ishga tushirishni ko'rish omadga bog'liq. Yomon ob-havo sharoiti tufayli ishga tushirishlar tez-tez o'zgartiriladi. Shuni yodda tuting: qat'iy sayohat jadvallari ishga tushirish bir yoki ikki kunga kechiktirilganda muammolarga olib keladi.",
        description_ru: "Космодром Байконур: одно из самых легендарных мест в истории освоения космоса, расположенное глубоко в бескрайних степях Казахстана. Проследите по следам Юрия Гагарина и внимательно посмотрите на запуск ракеты в русском стиле. Байконур — это небольшой российский анклав на территории Казахстана и ограниченная военная территория. На практике это означает, что посещение стоит дорого и его нужно планировать заранее, чтобы получить все необходимые документы и разрешения. Вы можете посетить его в любое время года, но, конечно же, увидеть запуск — это вишенка на торте. Однако возможность увидеть запуск зависит от удачи. Запуски часто переносятся из-за плохих погодных условий. Имейте это в виду: плотный график поездок вызывает проблемы, когда запуск задерживается на день или два.",
        description_en: "Baikonur Spaceport: one of the most storied places in the history of space exploration, deep in the boundless steppes of Kazakhstan. Trace the footsteps of Yuri Gagarin and get an up-close look of a rocket launch, Russian-style. Baikonur is a little Russian enclave inside Kazakhstan, and a restricted military domain. In practice, this means visiting is expensive and needs to be planned well in advance to get all the necessary papers and permits. You can visit any time of year, but of course, seeing a launch is the cherry on the cake. However, seeing a launch is dependent on luck. Launches get rescheduled often because of bad weather conditions. Be mindful of this: tight travel schedules cause problems when the launch gets delayed for a day or 2.",
        price_uz: "1300$ dan boshlab",
        price_ru: "от 1300$",
        price_en: "from 1300$",
        duration_uz: "3 kun, 2 kecha",
        duration_ru: "3 дней, 2 ночей",
        duration_en: "3 days, 2 nights",
        delay: "0.6s",
        image: "/img/package-3.jpg"
      }
    ]
  },
  getters: {
  },
  mutations: {
  },
  actions: {
  },
  modules: {
  }
})
