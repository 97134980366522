import { createRouter, createWebHashHistory, createWebHistory } from 'vue-router'

const routes = [
  {
    path: '/',
    name: 'Main',
    component: () => import("@/layout/MainLayout.vue"),
    redirect: {name: "Home"},
    children: [
      {
        path: 'home',
        name: 'Home',
        component: () => import('@/views/HomeView.vue'),
      },
      {
        path: '/about',
        name: 'About',
        component: () => import("@/views/AboutView.vue")
      },
      {
        path: '/packages',
        name: 'Packages',
        component: () => import("@/views/PackagesView.vue")
      },
      {
        path: '/services',
        name: 'Services',
        component: () => import("@/views/ServicesView.vue")
      },
      {
        path: '/contact',
        name: 'Contact',
        component: () => import("@/views/ContactView.vue")
      },
      {
        path: '/tour-detail/:id',
        name: 'TourDetail',
        component: () => import("@/views/TourDetail.vue")
      },
    ]
  },
  {
    path: '/admin',
    name: 'Admin',
    component: () => import("@/views/Admin.vue")
  },
]

const router = createRouter({
  history: createWebHistory(),
  routes
})

export default router
