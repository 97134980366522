export default {
  "home": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Главная"])},
  "about": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["О нас"])},
  "packages": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Пакеты"])},
  "services": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Услуги"])},
  "contact": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Контакт"])},
  "carouselText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Открой Мир Вместе С Нами"])},
  "carouselText2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Индивидуальные И Групповые Туры"])},
  "checkIn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Заезд"])},
  "checkOut": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Выезд"])},
  "yourName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ваше имя"])},
  "yourSurname": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ваша фамилия"])},
  "submit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Отправить"])},
  "aboutContent": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Мы специализируемся на небольших групповых походах, эко-турах, культурных и приключенческих турах; все наши туры предлагают уникальные возможности познакомиться с Узбекистаном очень аутентичным образом."])},
  "partners": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Партнеры"])},
  "staffs": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Сотрудники"])},
  "clients": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Клиенты"])},
  "exploreMore": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Подробнее"])},
  "ourServices": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Наши услуги"])},
  "service1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Визовые услуги"])},
  "service2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Авиабилеты"])},
  "service3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Железнодорожные билеты"])},
  "service4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Транспортные услуги"])},
  "service5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Бронирование гостиниц"])},
  "service6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Путеводители"])},
  "ourPackages": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Наши туристические пакеты"])},
  "ourPackagesText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Наша команда опытных профессионалов поможет вам получить максимум удовольствия от вашего удивительного путешествия."])},
  "subscribe": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Подписаться"])},
  "address": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["г. Наманган, улица А.Рахимова, 11"])},
  "menu": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Меню"])},
  "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ваш электронный адрес"])},
  "subject": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Тема"])},
  "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Сообщение"])},
  "book": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Бронировать"])},
  "phone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ваш номер телефона"])},
  "back": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Назад"])},
  "fillForm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Заполните поля"])}
}